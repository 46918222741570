/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Introducing the Lexus Luxury Yacht LY 680"), "\n", React.createElement(_components.h2, null, "Overview"), "\n", React.createElement(_components.p, null, "The Lexus Luxury Yacht LY 680 is a new luxury yacht introduced by Lexus, an upgraded version of the LY 650 yacht. It comes with exciting enhancements and features, powered by twin Volvo Penta IPS engines offering up to 2,700 horsepower in total. With a fuel tank capacity of 4,012 liters and a fresh water tank capacity of 852 liters, the LY 680 promises a luxurious experience on the water."), "\n", React.createElement(_components.h2, null, "Luxurious Design"), "\n", React.createElement(_components.p, null, "Designed to provide a lavish experience, the LY 680 boasts a spacious and comfortable interior crafted with high-quality materials and advanced technology. The yacht is equipped with amenities to ensure a pleasurable and convenient journey for its owners."), "\n", React.createElement(_components.h2, null, "Availability and Pricing"), "\n", React.createElement(_components.p, null, "The LY 680 is currently available for order in Japan, with the first deliveries expected in spring 2026. Priced similarly to its predecessor, the yacht starts at around $5.1 million. Availability and pricing may vary depending on the region and market."), "\n", React.createElement(_components.p, null, "For more information about the Lexus Luxury Yacht LY 680, you can visit the following sources:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://global.toyota/en/newsroom/lexus/40545977.html"
  }, "Lexus Introduces the Next Evolution of the Lexus Luxury Yacht, LY 680")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qz.com/lexus-yacht-luxury-vehicle-1851364812"
  }, "The most powerful Lexus ever is actually a $5 million yacht - Quartz")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.carscoops.com/2024/03/lexus-has-a-new-yacht-for-its-rich-customers-in-japan/"
  }, "New Lexus LY 680 Is A Super-Luxe Yacht With Up To 2700 HP")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Please note that the availability and pricing may vary depending on the region and market.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
